import {
  getSportByName,
  getTeamAbbreviation,
} from '@/constants/countries-leagues'
import { BsvIcon, LayersThree } from '@/icons/index'
import { calculateCoefficient, getSinglePossibleWin } from '@/utils/bets'
import { formatDate } from '@/utils/dates'
import React from 'react'

const formatCurrency = (value, usdToBsv, bsvToUsd, isBsvBet) => {
  const bsv = isBsvBet ? Number(value).toFixed(4) : usdToBsv(value).toFixed(4)
  const usd = isBsvBet
    ? bsvToUsd(Number(value)).toFixed(4)
    : Number(value).toFixed(4)
  return { bsv, usd }
}

export const BetIdRenderer = ({ data }) => (
  <div className="text-sm font-medium cursor-pointer" title={data?.betID}>
    {data?.betID}
  </div>
)

export const SportRenderer = ({ data }) => {
  if (!data) return null

  const renderMultiBet = () => (
    <div className="text-sm font-medium capitalize flex gap-x-2 w-full">
      <div className="bg-[#15191B] rounded-lg p-2.5">
        <LayersThree className="w-5 h-5" />
      </div>
      <div className="p-2">
        <span>{`${data.betType} (${data.betSlips.length})`}</span>
      </div>
    </div>
  )

  const renderSingleBet = () => {
    const sport = data?.match?.sport
    const currentSport = getSportByName(sport)

    return (
      <div className="text-sm font-medium capitalize flex gap-x-2 w-full">
        <div className="bg-[#15191B] rounded-lg p-2.5 relative">
          <currentSport.logo className="w-5 h-5" />
        </div>
        <div className="p-2">
          <span>{sport}</span>
        </div>
      </div>
    )
  }

  return data?.betSlips ? renderMultiBet() : renderSingleBet()
}

export const SportMobileRenderer = ({ data }) => {
  if (!data) return null

  const renderMultiBet = () => (
    <div className="text-sm font-medium capitalize flex gap-x-2 w-full">
      <div className="bg-[#15191B] rounded-lg p-2">
        <LayersThree className="w-5 h-5" />
      </div>
      <div className="p-2">
        <span>{`${data.betType} (${data.betSlips.length})`}</span>
      </div>
    </div>
  )

  const renderSingleBet = () => {
    const currentSport = getSportByName(data?.match?.sport)
    const teamAbbreviations = (
      <>
        {getTeamAbbreviation(data?.match?.league, data?.match?.home_team)} -{' '}
        {getTeamAbbreviation(data?.match?.league, data?.match?.away_team)}
      </>
    )

    return (
      <div className="text-sm font-medium capitalize flex gap-x-2 w-full">
        <div className="bg-[#15191B] rounded-lg p-2 relative">
          <currentSport.logo className="w-5 h-5" />
        </div>
        <div className="text-sm font-medium my-auto text-center">
          {data?.future ? (
            <div>{`${data.future.slice(0, 4)}...`}</div>
          ) : (
            <div>{teamAbbreviations}</div>
          )}
        </div>
      </div>
    )
  }

  return data?.betSlips ? renderMultiBet() : renderSingleBet()
}

export const BetRenderer = ({ usdToBsv, bsvToUsd, data }) => {
  const amount = data?.betSlips ? data?.price : data?.betPrice
  if (amount) {
    const { bsv, usd } = formatCurrency(
      amount,
      usdToBsv,
      bsvToUsd,
      data?.isBsvBet,
    )

    return (
      <div className="text-sm font-medium space-y-2">
        <div className="flex gap-x-2 items-center">
          <BsvIcon className="w-4 h-4" /> {bsv}
        </div>
        <div>$ {usd}</div>
      </div>
    )
  }
}

export const MatchRenderer = ({ data }) => {
  if (!data || data?.betSlips) return null

  return (
    <div className="text-sm font-medium space-y-2 text-center">
      {data?.future ? (
        <div className="whitespace-pre-wrap">{data.future}</div>
      ) : (
        <>
          <div>{data?.match?.home_team}</div>
          <div>{data?.match?.away_team}</div>
        </>
      )}
    </div>
  )
}

export const DateRenderer = ({ data }) => {
  if (!data || data?.betSlips) return null

  return (
    <div className="text-sm font-medium space-y-2">
      <div>{formatDate(data?.placedAt, 'MMM DD, YYYY')}</div>
      <div>{formatDate(data?.placedAt, 'hh:mm:ss a')}</div>
    </div>
  )
}

export const ResultRenderer = ({ data }) => {
  if (!data?.status) return null

  const status = {
    active: 'active',
    win: 'win',
    loss: 'lose',
    refund: 'push',
  }[data?.grade || data?.status]

  return (
    <StatusChip
      status={status}
      className={`${statusColors[status]} capitalize`}
    />
  )
}

export const PaymentStatusRenderer = ({ data }) => {
  if (!data?.status) return null

  const status = data?.status === 'active' ? 'active' : 'completed'
  return (
    <StatusChip
      status={status}
      className={`${statusColors[status]} capitalize`}
    />
  )
}

export const WinRenderer = ({ usdToBsv, bsvToUsd, data }) => {
  if (!data || data?.price === '' || data?.refunded) return null

  const formatPrice = (price, isBsv) =>
    isBsv
      ? parseFloat(price).toFixed(8)
      : usdToBsv(parseFloat(price))?.toFixed(8)

  const formatUsd = (price, isBsv) =>
    isBsv
      ? bsvToUsd(parseFloat(price))?.toFixed(8)
      : parseFloat(price)?.toFixed(8)

  const renderPrice = () => {
    if (data?.grade === 'win') {
      return data?.betSlips
        ? formatPrice(data?.possibleWinPrice, data?.isBsvBet)
        : formatPrice(
            getSinglePossibleWin({
              betPrice: data?.betPrice,
              price: data?.price,
            }),
            data?.isBsvBet,
          )
    } else {
      return data?.betSlips
        ? `-${formatPrice(data?.price, data?.isBsvBet)}`
        : `-${formatPrice(
            isNaN(data?.betPrice) ? 0.0 : data?.betPrice,
            data?.isBsvBet,
          )}`
    }
  }

  const renderUsd = () => {
    if (data?.grade === 'win') {
      return data?.betSlips
        ? formatUsd(data?.possibleWinPrice, data?.isBsvBet)
        : formatUsd(
            getSinglePossibleWin({
              betPrice: data?.betPrice,
              price: data?.price,
            }),
            data?.isBsvBet,
          )
    } else {
      return data?.betSlips
        ? `-${formatUsd(data?.price, data?.isBsvBet)}`
        : `-${formatUsd(
            isNaN(data?.betPrice) ? 0.0 : data?.betPrice,
            data?.isBsvBet,
          )}`
    }
  }

  return (
    <div className="text-sm font-medium space-y-2">
      {data?.status !== 'active' && (
        <div className="flex gap-x-2 items-center relative">
          <BsvIcon className="w-4 h-4" />
          {renderPrice()}
        </div>
      )}
      {data?.status !== 'active' && (
        <div>
          {'$ '}
          {renderUsd()}
        </div>
      )}
    </div>
  )
}

export const CoefRenderer = ({ data }) => {
  if (!data?.price) return null

  return (
    <StatusChip
      status={calculateCoefficient(data)}
      className={`${statusColors.grey} capitalize`}
    />
  )
}

export const StatusChip = ({ status: value, className = '' }) => {
  return (
    <span
      className={`${
        className ? className : 'bg-white/10'
      } rounded-2xl px-2 py-0.5 font-inter font-medium text-sm whitespace-nowrap w-fit`}
    >
      {value || ''}
    </span>
  )
}

export const statusColors = {
  active: 'text-[#F7941D]',
  push: 'text-[#F7941D]',
  win: 'text-[#12B76A]',
  lose: 'text-[#F04438]',
  grey: 'bg-white/10 text-white',
  yellow: 'bg-[#F79009]/20 text-[#F79009]',
  red: 'bg-[#F04438]/10 text-[#F04438]',
  blue: 'bg-[#0096FF]/10 text-[#0096FF]',
  green: 'bg-[#12B76A]/10 text-[#12B76A]',
  completed: 'text-[#12B76A]',
  na: 'text-[#F04438]',
}

export const dateComparator = (date1, date2) => {
  const time1 = new Date(date1).getTime()
  const time2 = new Date(date2).getTime()

  if (!time1 && !time2) return 0
  if (!time1) return -1
  if (!time2) return 1

  return time1 - time2
}

export const numberSort = (num1, num2) => {
  return num1 - num2
}
