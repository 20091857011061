import { firebasaGetBetsWithPaginate } from '@/firebase/utils'
import useExchangeRates from '@/hooks/useExchangeRates'
import authSelector from '@/redux/selectors/auth'
import { ModuleRegistry } from '@ag-grid-community/core'
import { InfiniteRowModelModule } from '@ag-grid-community/infinite-row-model'
import { AgGridReact } from '@ag-grid-community/react'
import { useRouter } from 'next/router'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import {
  BetIdRenderer,
  BetRenderer,
  CoefRenderer,
  DateRenderer,
  MatchRenderer,
  PaymentStatusRenderer,
  ResultRenderer,
  SportMobileRenderer,
  SportRenderer,
  WinRenderer,
  numberSort,
} from '../components'

ModuleRegistry.registerModules([InfiniteRowModelModule])

const InfiniteScroll = ({
  onIdPress,
  isHome = false,
  isMyBets,
  isSuccess = false,
  isFailed = false,
}) => {
  const [lastVisible, setLastVisible] = useState(null)
  const lastVisibleRef = useRef(lastVisible)
  const { usdToBsv, bsvToUsd } = useExchangeRates()
  const sm = useMediaQuery({ query: '(max-width: 639px)' })
  const { user, isAuthenticated } = useSelector(authSelector)
  const router = useRouter()
  const intl = useIntl()

  const isAdmin = user?.roleType === 'admin'
  const isAdminBets =
    router.pathname.includes('bets') && isAdmin && isAuthenticated

  const gridStyle = { height: '100%', width: '100%', boxSizing: 'border-box' }

  const onCellClicked = ({ data }) => onIdPress(data)

  const baseColumns = [
    {
      headerName: intl.formatMessage({
        id: 'table.betId',
        defaultMessage: 'Bet ID',
      }),
      field: 'betID',
      cellRenderer: BetIdRenderer,
      onCellClicked,
      minWidth: 100,
    },
    {
      headerName: intl.formatMessage({
        id: 'table.sport',
        defaultMessage: 'Sport',
      }),
      field: 'match.sport',
      cellRenderer: SportRenderer,
      onCellClicked,
      minWidth: 170,
    },
    {
      headerName: intl.formatMessage({
        id: 'table.result',
        defaultMessage: 'Result',
      }),
      field: 'status',
      cellRenderer: ResultRenderer,
      minWidth: 110,
    },
    {
      headerName: intl.formatMessage({
        id: 'table.match',
        defaultMessage: 'Match',
      }),
      field: 'match.home_team',
      cellRenderer: MatchRenderer,
      onCellClicked,
      minWidth: 150,
    },
    {
      headerName: intl.formatMessage({
        id: 'table.bet',
        defaultMessage: 'Bet',
      }),
      field: 'betPrice',
      cellRenderer: (props) => (
        <BetRenderer usdToBsv={usdToBsv} bsvToUsd={bsvToUsd} {...props} />
      ),
      onCellClicked,
      minWidth: 150,
    },
    {
      headerName: intl.formatMessage({
        id: 'table.date',
        defaultMessage: 'Date/Time',
      }),
      field: 'placedAt',
      cellRenderer: DateRenderer,
      onCellClicked,
      minWidth: 150,
    },
    {
      headerName: intl.formatMessage({
        id: 'table.win',
        defaultMessage: 'Win',
      }),
      field: 'betPrice',
      cellRenderer: (props) => (
        <WinRenderer usdToBsv={usdToBsv} bsvToUsd={bsvToUsd} {...props} />
      ),
      onCellClicked,
      minWidth: 150,
    },
    {
      headerName: intl.formatMessage({
        id: 'table.coef',
        defaultMessage: 'Coef',
      }),
      field: 'price',
      cellRenderer: CoefRenderer,
      comparator: numberSort,
      onCellClicked,
      minWidth: 130,
    },
  ]

  const adminColumns = isAdminBets
    ? [
        {
          headerName: 'Payment Status',
          field: 'status',
          cellRenderer: PaymentStatusRenderer,
          minWidth: 110,
        },
      ]
    : []

  const columnDefs = [...baseColumns, ...adminColumns]
  const mobileColumnDefs = [
    {
      headerName: 'Event',
      field: 'match',
      cellRenderer: SportMobileRenderer,
      onCellClicked,
      minWidth: 150,
    },
    {
      headerName: 'Result',
      field: 'status',
      cellRenderer: ResultRenderer,
      onCellClicked,
      minWidth: 150,
    },
    {
      headerName: 'Bet Amount',
      field: 'betPrice',
      cellRenderer: (props) => (
        <BetRenderer usdToBsv={usdToBsv} bsvToUsd={bsvToUsd} {...props} />
      ),
      onCellClicked,
      minWidth: 150,
    },
  ]

  const defaultColDef = useMemo(
    () => ({ flex: 1, minWidth: 150, sortable: false }),
    [],
  )

  useEffect(() => {
    lastVisibleRef.current = lastVisible
  }, [lastVisible])

  const fetchNextChunk = async (successCallback) => {
    const data = await firebasaGetBetsWithPaginate(
      lastVisibleRef.current,
      isMyBets ? user?.uid : null,
      isSuccess,
      isFailed,
    )
    setLastVisible(data.lastDoc)
    successCallback(data.bets, data.bets.length === 0 ? true : null)
  }

  const dataSource = {
    rowCount: undefined,
    getRows: async (params) => {
      await fetchNextChunk((newData, lastRow) => {
        if (!lastRow) {
          if (newData.length < 20) {
            params.successCallback(
              newData,
              params.endRow - (20 - newData.length),
            )
          } else {
            params.successCallback(newData)
          }
        } else {
          if (params.endRow === 20 && newData.length === 0) {
            params.successCallback(undefined, 0)
          }
        }
      })
    },
  }

  const getRowId = useCallback((params) => params.data.betID, [])

  return (
    <div
      className={`h-[500px] pb-[0.5] mx-auto my-8 ${
        isHome ? 'w-full' : 'w-[95%] 2xl:w-[97%]'
      }`}
    >
      <div style={gridStyle} className="ag-theme-quartz-dark">
        <AgGridReact
          columnDefs={sm ? mobileColumnDefs : columnDefs}
          rowHeight={70}
          defaultColDef={defaultColDef}
          animateRows
          sortModel={[{ colId: 'placedAt', sort: 'asc' }]}
          overlayNoRowsTemplate="<span style='padding: 10px; border: 2px solid #444;'>No Results Found</span>"
          overlayLoadingTemplate={'<span class="plinko-loader"></span>'}
          rowModelType="infinite"
          getRowId={getRowId}
          onGridReady={(params) =>
            params.api.setGridOption('datasource', dataSource)
          }
          cacheBlockSize={20}
        />
      </div>
    </div>
  )
}

export default InfiniteScroll
