const FormInput = ({
  label,
  className,
  mainClass,
  labelClass,
  icon,
  required,
  isRight,
  showBorder = true,
  background,
  onIconClick = undefined,
  ...props
}) => {
  const { id } = props
  return (
    <div className={`${mainClass}`}>
      {label && (
        <label
          htmlFor={id}
          className={`block text-sm font-medium mb-1.5 ${labelClass}`}
        >
          {label}
          {required && <span className="text-red-500">*</span>}
        </label>
      )}

      <div
        className={`flex flex-row items-center rounded-lg p-2 ${
          background
            ? 'bg-[#ffffff1a]'
            : props?.disabled
            ? 'bg-gray-700'
            : 'bg-white/5'
        }  ${showBorder && 'border border-white/20'} ${className} `}
      >
        {icon && !isRight && (
          <div
            onClick={onIconClick}
            className={`flex items-center cursor-pointer relative`}
          >
            {icon}
          </div>
        )}
        <input
          id={id}
          {...props}
          required={required}
          className={`
           appearance-none border-none outline-none p-0 py-[1px] block w-full pl-2 pr-2 text-base bg-transparent font-normal placeholder:text-[#6B7280] focus:outline-none`}
        />
        {icon && isRight && (
          <div className={`flex items-center cursor-pointer`}>{icon}</div>
        )}
      </div>
    </div>
  )
}

export default FormInput
